<script>
import AdvancedTable from "../../../components/elements/advanced-table.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        AdvancedTable,
    },

    props: {
        type: {
            type: String,
            default: 'all'
        }
    },

    computed: {
        ...mapGetters({
            dynamicFields: 'partner/fields',
            authenticated: 'user/authenticated',
            admin: 'auth/admin'
        }),

        fields: function () {
            const fields = [
                {
                    key: "code",
                    label: this.$t('partners::partners.columns.code').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "name",
                    label: this.$t('partners::partners.columns.name').ucFirst(),
                    thClass: '',
                    sortable: true
                }
            ];

            if (this.type !== 'free') {
                fields.push(
                    {
                        key: "users",
                        label: this.$t('partners::partners.relationships.users').ucFirst(),
                        formatter: (value) => value ? value.map(user => user.name).join(', ') : null,
                        thClass: '',
                        sortable: true
                    }
                );
            }

            if (this.admin) {
                fields.push({
                        key: "registration_number",
                        label: this.$t('partners::partners.columns.registration_number').ucFirst(),
                        thClass: '',
                        sortable: true
                    },
                    {
                        key: "vat_number",
                        label: this.$t('partners::partners.columns.vat_number').ucFirst(),
                        thClass: '',
                        sortable: true
                    },
                    {
                        key: "email",
                        label: this.$t('partners::partners.columns.email').ucFirst(),
                        thClass: '',
                        sortable: true
                    },
                    {
                        key: "phone",
                        label: this.$t('partners::partners.columns.phone').ucFirst(),
                        thClass: '',
                        sortable: true
                    },);
            }

            if (this.dynamicFields && this.dynamicFields.length) {
                const dynamicFields = this.dynamicFields.filter(field => field && field.options && field.options.display_as_table_column);

                dynamicFields.forEach((field) => {
                    fields.push(
                        {
                            key: field.transformed_name,
                            label: field.translation_key ? this.$t(field.translation_key).ucFirst() : field.name,
                            thClass: '',
                            sortable: false
                        },
                    )
                });
            }

            fields.push(
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                }
            )

            return fields;
        },

        createLink: function () {
            return { name: this.type === 'my' ? 'my-partner-create' : 'partner-create', params: {type: this.$t('partners::partners.types.' + this.type)} };
        }
    },

    data() {
        return {};
    },

    methods: {
        addProject: function (item) {
            this.$store.dispatch('project/setItem', {
                target_type: 'Appon\\Partners\\Models\\Partner',
                target_id: item.id,
                user_type: 'App\\Models\\User',
                user_id: this.authenticated.id
            })

            this.$router.push({ path: this.$t('routes./projects/my-projects/create') })
        },

        getIt: function (item, callback) {
            if (!item.users) {
                item.users = [this.authenticated.id]
            } else if (Array.isArray(item.users)) {
                item.users.push(this.authenticated.id)
            }

            this.$store.dispatch('partner/update', Object.assign({with: ['fields', 'fields.fieldType', 'fieldValues', 'fieldValues.fieldVersion'], main_user: this.authenticated.id}, item)).then(() => {
                if (typeof callback === 'function') {
                    callback()
                }
            })
        },

        editable: function (partner) {
            if (this.admin) {
                return true
            }

            if (this.type === 'my' || this.type === 'free') {
                return this.can('partners.update');
            }

            if (Array.isArray(partner.users) && partner.users.length) {
                return !! partner.users.find(user => user.id === this.authenticated.id)
            }

            return false
        },
    },

    created() {
        this.$store.dispatch('partner/fields', { owner_type: 'Appon\\Partners\\Models\\Partner', with: 'fieldType' })
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'partners::partners.partner', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        autocomplete="partner"
        :caption="$tc('partners::partners.partner', 2).ucFirst()"
        :create-link="createLink"
        :current-page="1"
        :edit-link="{ name: 'partner-edit', params: {type: $route.params.type} }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :per-page="10"
        :queries="{ type: type, with: ['users'] }"
        :to-be-forwarded="{ type: $t('partners::partners.types.' + type) }"
        borderless
        :deletable="type !== 'free' || admin"
        editable
        hover
        moduleNamespace="partner"
        outlined
        permission="partners"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="name"
        sort-direction="asc"
        sort-icon-left
    >
        <template #users="{value}">
            <div class="avatar-group">
                <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                    <a v-if="user.image && user.image.path" href="javascript: void(0);" class="d-inline-block">
                        <img :src="user.image.path"
                             :alt="user.name"
                             :title="user.name"
                             class="rounded-circle avatar-sm">
                    </a>
                    <div v-else class="avatar-sm cursor-pointer">
                        <span :title="user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.name.firstLetters() }} </span>
                    </div>
                </div>
            </div>
        </template>
        <template #more-action="{ item, fetch }">
            <li v-if="type === 'my'" class="list-inline-item">
                <a
                    v-if="can('partners.update')"
                    @click.prevent="addProject(item, fetch)"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('base.add_new_item', {item: $tc('projects::projects.project', 1)}).ucFirst()"
                    class="px-2 text-dark cursor-pointer"
                ><i class="bx bx-folder-plus font-size-18"></i
                ></a>
            </li>
            <li v-if="type === 'free'" class="list-inline-item">
                <a
                    v-if="can('partners.update')"
                    @click.prevent="getIt(item, fetch)"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('base.add_it_to_mine').ucFirst()"
                    class="px-2 text-info cursor-pointer"
                ><i class="bx bx-user-plus font-size-18"></i
                ></a>
            </li>
        </template>
        <template v-if="type === 'all'" #actions="{ item, methods }">
            <li class="list-inline-item">
                <router-link
                    v-if="can('partners.update') && editable(item)"
                    :to="methods.getEditLink(item.id)"
                    :title="$t('base.edit').ucFirst()"
                    class="px-2 text-primary"
                >
                    <i class="bx bx-pencil font-size-18"></i>
                </router-link>
            </li>
            <li class="list-inline-item">
                <a
                    v-if="can('partners.delete') && editable(item)"
                    @click.prevent="methods.destroy(item.id)"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('base.delete').ucFirst()"
                    class="px-2 text-danger cursor-pointer"
                ><i class="bx bx-trash-alt font-size-18"></i
                ></a>
            </li>
        </template>
        <template #extra-actions="{ item }">
                <span :id="item.name" class="dropdown-item cursor-pointer">
                    <i class="mdi mdi-plus-box-multiple-outline font-size-14 me-2"></i>
                    <span>Extra action</span>
                </span>
        </template>
    </advanced-table>
</template>
